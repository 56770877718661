* {
  box-sizing: border-box;
}

.App {
  text-align: center;
  background-color:ivory;
  color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: ivory;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn ease 1s;
  animation-fill-mode: forwards;
}

.nav-menu {
  background-color: #00102E;
  color: #fff;
  margin: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 0 1rem;
  height: 3rem;
}

.nav-menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
}

.nav-menu li {
  float: right;
  gap: 1rem;
}

.nav-menu li a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0.375rem;
}

.nav-menu li a:hover {
  color: #d65050;
}

.contact {
  width: 50px;
  height: 50px;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.contact a {
  height: 50px;
  width: 30px;
  margin-bottom: 0.5rem;
  text-decoration: none;
}

.contact a img {
  width: 40px;
  height: 40px;
  filter: invert(8%) sepia(15%) saturate(6788%) hue-rotate(195deg) brightness(98%) contrast(107%); 
  border-radius: 100%; 
}

.contact a img:hover {
  filter: invert(31%) sepia(82%) saturate(653%) hue-rotate(316deg) brightness(111%) contrast(88%);
}

@media screen and (max-width: 800px) {
  .contact a {
    height: 50px;
    width: 30px;
  }
}

.horizontal-line {
  width: 250px;
  height: 0;
  border-top: 2px solid black; 
  margin: 10px auto;
}
