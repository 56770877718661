.contacts-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 15px; 
    background-color: #f9f9f9; 
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); 
}

.contacts-text {
    text-align: center;
    margin: 20px;
}

.contacts-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
.contacts-form label {
    text-align: left;
    font-weight: bold;
    margin-bottom: 5px;
}
  
.contacts-form input[type='text'],
.contacts-form textarea {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}
  
.contacts-form textarea {
    height: 100px;
    resize: vertical;
}
  
.contacts-form input[type='submit'] {
    background-color: #4CAF50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}
  
.contacts-form input[type='submit']:hover {
    background-color: #45a049;
}
  
@media (max-width: 600px) {
    .contacts-form {
        width: auto;
        margin: 10px;
    }
}

