.projects-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* color: #233452; */
    margin: 0;
    padding: 0;
}

.projects {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem; 
    justify-content: space-between; 
    align-items: flex-start;
    min-height: 100vh;
    width: 100%;
    text-align: center;
    padding: 0 1rem; 
    text-align: left;
}

.project-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 35vh; 
    width: 45vw; 
    margin: 0.25rem;
    padding: 1rem;
    border-radius: 10px;
    text-decoration: none;
    color: black;
    background-color: #f7fbff;
    transition: box-shadow 0.3s ease-in-out;
}

.project-box h2 {
    text-align: left;
}

.project-box:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 800px) {
    .projects {
        display: flex;
        flex-direction: column;
        align-items: center; /* was center */
        justify-content: center; /* was center */
        min-height: 100vh;
        width: calc(100% - 110px);
        text-align: center;
        margin-right: 1rem;
        margin-left: 1rem;
        text-align: left;
    }

    .project-box {
        /* width: 80vw; */
        min-height: auto;
        min-height: 30vh;
        width: 87vw;
    }

    .project-box h2 {
        /* font-size: 4vw; */
    }
}
