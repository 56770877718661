/* Resume Styling */

.projects-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* color: #233452; */
    margin: 0;
    padding: 0;
}

.projects {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    min-height: 100vh;
    width: 100%;
    text-align: center;
    margin-right: 1rem;
    margin-left: 1rem;
    text-align: left;
}

.resume {
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
    padding: 1rem;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.resume h1 {
    text-align: center;
    margin-bottom: 1rem;
}

@media screen and (max-width: 800px) {
    .projects {
        display: flex;
        flex-direction: column;
        align-items: center; /* was center */
        justify-content: center; /* was center */
        min-height: 100vh;
        width: calc(100% - 110px);
        text-align: center;
        margin-right: 1rem;
        margin-left: 1rem;
        text-align: left;
    }

    .resume {
        margin: 0 auto;
        width: 100%;
        max-width: 800px;
        padding: 1rem;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }

    .resume iframe {
        width: 70%;
    }
}