/* Home Page Styles */

  .home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background-color: #FFF8F0;
  }
  
  .home-header {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  .home-text {
    text-align: right;
    margin: 20px;
  }
  
  .home-image {
    border-radius: 60%;
    width: 300px;
    margin-bottom: 100px;
    margin-top: 100px;
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
  
  .scroll-indicator {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    font-size: 2rem;
    color: white;
    background-color: rgb(0, 38, 92);
    animation: bounce 2s infinite;
  }
  
  .bio {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    height: 100vh;
    padding: 0 2.5rem;
    margin-bottom: 0;
  }

  .bio-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    padding: 0 2.5rem;
    margin-bottom: 0;
    width: 60%;
    height: auto;
  }
  
  .bio-header {
    align-items: center;
    display: flex;
  }
  
  .bio-text {
    text-align: left;
    margin: 20px;
  }
  
  @media (max-width: 800px) {
    .home-header {
      flex-direction: column;
    }
  
    .home-text {
      width: 100%;
      text-align: center;
      margin-right: 0;
      margin-bottom: 15px;
    }

    .bio-text-container {
      width: 90%;
    }
  
    .bio-text {
      width: 100%;
      text-align: center;
      margin-right: 0;
      margin-bottom: 15px;
    }
  
    .home-image {
      width: 300px;
      margin-top: 0px;
    }
  }